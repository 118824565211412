import { Role } from "@/dto/employer/employer";
import { Employer } from "@/dto/employer/employer";
import { useAuthStore } from "./auth";
import { useFetchStateStore } from "@/store/fetch-state";

export const useTeamStore = defineStore("team", () => {
  const team = ref<Employer[]>();
  const fetchStateStore = useFetchStateStore();

  const getTeam = async () => {
    await fetchStateStore.fetchOnce("team", async () => {
      team.value = await $api<Employer[]>(API_ROUTES.team);
    });
  };

  const inviteTeamMember = async (memberData: Partial<Employer>) => {
    const member = await $api<Employer>(API_ROUTES.teamInvite, {
      method: "post",
      body: { email: memberData.email, teamRoleId: memberData.role?.id },
    });
    return member;
  };

  const cancelInvite = async (userId: string) => {
    await $api<Employer>(`${API_ROUTES.teamInvite}/${userId}`, {
      method: "delete",
    });
    removeMemberFromStore(userId);
  };

  const deleteTeamMember = async (userId: string) => {
    try {
      await $api<Employer>(`${API_ROUTES.teamUser}/${userId}`, {
        method: "delete",
      });
      removeMemberFromStore(userId);
    } catch (e) {
      throw createError({ message: ERROR_MESSAGES.team_leave_error });
    }
  };

  const updateTeamMemberRole = async (userId: string, role: Role) => {
    const member = await $api<Employer>(`${API_ROUTES.teamUser}/${userId}`, {
      method: "patch",
      body: { role },
    });

    if (member && team.value && team.value.length) {
      const teamMember = team.value.find((m: Employer) => m.id === member.id);
      if (teamMember) teamMember.role = member.role;
    }
  };

  const addMemberToStore = (member: Employer) => {
    const index = getMemberIndex(member.id);
    if (index === -1) team.value?.push(member);
  };

  const removeMemberFromStore = async (id: string) => {
    const user = useSupabaseUser();
    if (id === user.value?.id) {
      await useAuthStore().signOut();
      return navigateTo(ROUTES.login);
    }

    const index = getMemberIndex(id);
    if (index !== -1) team.value?.splice(index, 1);
  };

  const updateMemberInStore = (member: Employer) => {
    const index = getMemberIndex(member.id);
    if (index !== -1) team.value![index] = member;
  };

  const getMemberIndex = (id: string) => {
    return team.value?.findIndex((m) => m.id === id) ?? -1;
  };

  return {
    team,
    getTeam,
    inviteTeamMember,
    cancelInvite,
    updateTeamMemberRole,
    deleteTeamMember,
    addMemberToStore,
    removeMemberFromStore,
    updateMemberInStore,
  };
});
